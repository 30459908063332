
import { JournalAPI } from '@/js-api'
import { dateSort, volIssTable } from '@/utils'
import { mixins } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import { IdType, Item } from './types'
import VolIssMixin from './volIssMixin'

@Component
export default class IssueList extends mixins(VolIssMixin) {
  items: {year: number, formatted: Item}[] = []
  filteredItems: Item[] = []

  //
  // LIFE CYCLE HOOKS
  //
  mounted() {
    this.fetchData()
  }

  //
  // WATCHERS
  //
  @Watch('$route', { immediate: true })
  onRouteChanged() {
    this.lastSelectedItemId = this.$route.query.last as string
    this.startYear = this.$route.query.startYear ? +this.$route.query.startYear : -1
    this.endYear = this.$route.query.endYear ? +this.$route.query.endYear : -1
    this.updateFilteredItems()
  }

  @Watch('items')
  onItemsChanged() {
    this.updateFilteredItems()
  }

  //
  // METHODS
  //
  async fetchData() {
    this.loading = true

    const groupId = await this.getGroupId()
    const response = await JournalAPI.issues(groupId)
    const allIssues = response
      .reduce((acc, journal) => ([
        ...acc,
        ...journal.issues.results
          .filter(entry => entry.year !== null)
          .map(issue => ({ journal, issue })),
      ]), [])
      .sort((a, b) => (dateSort(a.issue.year, b.issue.year)))

    this.total = response.reduce((acc, journal) => acc + journal.issues.total, 0)
    this.syncedTitle = allIssues.length ? allIssues[0].journal.jortitle : ''

    this.yearOptions = [...new Set<number>(allIssues.map(entry => +entry.issue.year))].sort((a, b) => b - a)

    this.items = allIssues.map(entry => {
      return {
        year: +entry.issue.year,
        formatted: volIssTable(
          [entry.issue],
          false, // is not series
          this.total,
          entry.journal.jourid
        )[0],
      }
    })

    this.loading = false
  }

  async getGroupId() {
    let groupId = this.id

    if (this.idType === IdType.Journal) {
      groupId = await JournalAPI.getGroupId(this.id)
    }

    return groupId
  }

  isYearIncluded(itemYear: number) {
    if (this.startYear !== -1 && this.endYear === -1) {
      return itemYear >= this.startYear
    } else if (this.startYear !== -1 && this.endYear !== -1) {
      if (this.startYear > this.endYear) {
        return itemYear >= this.endYear && itemYear <= this.startYear
      } else {
        return itemYear >= this.startYear && itemYear <= this.endYear
      }
    }

    return true
  }

  async updateRoute() {
    const query = {
      ...this.$route.query,
      startYear: this.startYear > 0 ? this.startYear.toString() : undefined,
      endYear: this.endYear > 0 ? this.endYear.toString() : undefined,
      last: undefined,
    }

    // Don't update route if url params are the same
    if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
      return
    }

    this.$router.push({ query })
  }

  applyFilters() {
    this.updateFilteredItems()
    this.updateRoute()
  }

  clearFilters() {
    this.updateFilteredItems()
    this.updateRoute()
  }

  applyBottomFilters() {
    this.applyFilters()
    this.scrollToTopFilters()
  }

  clearBottomFilters() {
    this.clearFilters()
    this.scrollToTopFilters()
  }

  updateFilteredItems() {
    this.filteredItems = this.items
      .filter(item => this.isYearIncluded(item.year))
      .map(item => item.formatted)
  }
}
