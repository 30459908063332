
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class VolIssFilters extends Vue {
  @PropSync('startYear') syncedStartYear
  @PropSync('endYear') syncedEndYear
  @Prop() yearOptions!: number[]

  unappliedChanges = false

  //
  // COMPUTED PROPERTIES
  //
  get startYearOptions() {
    const init = { value: -1, text: 'All Years' }

    if (this.yearOptions.length === 0) return [init]

    return [
      init,
      ...this.yearOptions.map(year => ({ value: year, text: year.toString() })),
    ]
  }

  get endYearOptions() {
    const init = { value: -1, text: '----' }

    if (this.yearOptions.length === 0) return [init]

    return [
      init,
      ...this.yearOptions.map(year => ({ value: year, text: year.toString() })),
    ]
  }

  get allYearsSelected() {
    return this.syncedStartYear === -1
  }

  get showCleanButton() {
    return this.unappliedChanges || this.syncedStartYear !== -1 || this.syncedEndYear !== -1
  }

  //
  // METHODS
  //

  handleYearChange() {
    this.unappliedChanges = true
  }

  applyFilters() {
    this.unappliedChanges = false
    this.$emit('applyFilters')
  }

  clearFilters() {
    this.unappliedChanges = false
    this.syncedStartYear = -1
    this.syncedEndYear = -1
    this.$emit('clearFilters')
  }
}
