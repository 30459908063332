export enum IdType {
  Journal = 'journal',
  Series = 'series',
  Group = 'group',
}

export type Item = {
  id: string;
  text: string;
  to: { name: string; query: { articleId: string; query?: undefined } } | undefined;
  pending: boolean;
  statusUrl: string;
  hasSpine: boolean;
  spineUrl: null
}

export type SearchParams = {
  id: number;
  startYear: number;
  endYear: number;
  lastSelectedItem: string;
}
