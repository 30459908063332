
import { Vue, Component, Watch } from 'vue-property-decorator'
import { IdType } from '@/components/journal/types'
import { PageHeader } from '@/components/page-header'
import IssueList from '@/components/journal/IssueList.vue'
import VolumeList from '@/components/journal/VolumeList.vue'

@Component({
  components: {
    PageHeader,
    IssueList,
    VolumeList,
  },
})
export default class VolumeIssueList extends Vue {
  idType?: IdType
  id?: number
  // lastSelectedItem?: string

  title = ''
  // items: {year: number, formatted: Item}[] = []
  // localTotal = 0
  // loading = true
  // currentPage = 1
  // totalToShow = 100
  // increaseBy = 100
  // elasticsearchLimit = 10000
  // localYearOptions: number[] = []
  // startYear = -1
  // appliedStartYear = -1
  // endYear = -1
  // appliedEndYear = -1
  // shouldFetch = true

  // //
  // // LIFECYCLE HOOKS
  // //
  // updated() {
  //   updateMathJax()
  // }

  //
  // WATCHERS
  //
  @Watch('$route', { immediate: true })
  onRouteChange() {
    this.checkURLParams()
    // this.fetchData()
  }

  // @Watch('items')
  // onItemsChange() {
  //   if (this.lastSelectedItem) {
  //     this.$nextTick(() => {
  //       this.markLastSelectedItem()
  //     })
  //   }
  // }
  @Watch('title')
  onTitleChange() {
    document.title = this.serialPubType + ' List for ' + this.title + ' - MathSciNet'
  }

  mounted() {
    document.title = this.serialPubType + ' List - MathSciNet'
  }

  //
  // COMPUTED PROPERTIES
  //
  get serialPubType() {
    return this.idType === IdType.Series ? 'Volume' : 'Issue'
  }

  get profileURL() {
    let query = {}
    switch (this.idType) {
      case IdType.Group:
        query = { groupId: this.id }
        break
      case IdType.Series:
        query = { seriesId: this.id }
        break
      case IdType.Journal:
        query = { journalId: this.id }
        break
    }

    return {
      name: 'SerialProfile',
      query,
    }
  }

  get isSeries() {
    return this.idType === IdType.Series
  }

  // get totalPaginationRows() {
  //   return this.total > 1000 ? 1000 : this.total
  // }

  // get internal() {
  //   return this.$store.state.AppConfigModule.appConfig.internal
  // }

  // get shrinkedItems() {
  //   return this.items
  //     .filter(item => {
  //       if (this.isFiltered) return this.isYearIncluded(item.year)

  //       return true
  //     })
  //     .map(item => item.formatted)
  //     .slice(0, this.totalToShow)
  // }

  // get canShowMore() {
  //   if (this.idType === IdType.Series) {
  //     return this.shrinkedItems.length < this.total && this.shrinkedItems.length < this.elasticsearchLimit
  //   }

  //   return this.shrinkedItems.length < this.total
  // }

  // get startYearOptions() {
  //   const init = { value: -1, text: 'All Years' }

  //   if (this.localYearOptions.length === 0) return [init]

  //   return [
  //     init,
  //     ...this.localYearOptions.map(year => ({ value: year, text: year.toString() })),
  //   ]
  // }

  // get endYearOptions() {
  //   const init = { value: -1, text: '----' }

  //   if (this.localYearOptions.length === 0) return [init]

  //   return [
  //     init,
  //     ...this.localYearOptions.map(year => ({ value: year, text: year.toString() })),
  //   ]
  // }

  // get allYearsSelected() {
  //   return this.startYear === -1
  // }

  // get disableApplyButton() {
  //   return this.startYear === this.appliedStartYear && this.endYear === this.appliedEndYear
  // }

  // get disableClearButton() {
  //   return this.startYear === -1 && this.endYear === -1
  // }

  // get isFiltered() {
  //   return this.appliedStartYear !== -1
  // }

  // set total(value: number) {
  //   this.localTotal = value
  // }

  // get total() {
  //   if (this.isFiltered && this.idType !== IdType.Series) {
  //     return this.items.filter(item => this.isYearIncluded(item.year)).length
  //   }

  //   return this.localTotal
  // }

  //
  // METHODS
  //
  checkURLParams() {
    // ID and ID Type
    const id = this.$route.query.groupId || this.$route.query.seriesId || this.$route.query.journalId
    if (id) {
      this.id = +id
    }

    if (this.$route.query.groupId) {
      this.idType = IdType.Group
    } else if (this.$route.query.seriesId) {
      this.idType = IdType.Series
    } else if (this.$route.query.journalId) {
      this.idType = IdType.Journal
    }

    // if (this.$route.query.last) this.lastSelectedItem = this.$route.query.last as string
    // this.startYear = this.$route.query.startYear ? +this.$route.query.startYear : -1
    // this.endYear = this.$route.query.endYear ? +this.$route.query.endYear : -1
  }

  // async fetchData() {
  //   if (!this.id) return
  //   if (!this.shouldFetch) return

  //   if (this.idType === IdType.Series) {
  //     this.fetchVolumes()
  //   } else if (this.idType === IdType.Group || this.idType === IdType.Journal) {
  //     this.fetchIssues()
  //   }
  // }

  // getYearRange() {
  //   const yearRange: { start?: number, end?: number } = {
  //     start: undefined,
  //     end: undefined,
  //   }

  //   if (this.appliedStartYear !== -1 && this.appliedEndYear === -1) {
  //     yearRange.start = this.appliedStartYear
  //   } else if (this.appliedStartYear !== -1 && this.appliedEndYear !== -1) {
  //     if (this.appliedStartYear > this.appliedEndYear) {
  //       yearRange.start = this.appliedEndYear
  //       yearRange.end = this.appliedStartYear
  //     } else {
  //       yearRange.start = this.appliedStartYear
  //       yearRange.end = this.appliedEndYear
  //     }
  //   }

  //   return yearRange
  // }

  // async fetchVolumes(options:{append: boolean, ignoreYearsAggs: boolean} = { append: false, ignoreYearsAggs: false }) {
  //   this.loading = true

  //   const yearRange = this.getYearRange()

  //   const response = await SeriesAPI.volumes(
  //     this.id,
  //     this.currentPage,
  //     this.increaseBy,
  //     yearRange.start,
  //     yearRange.end
  //   )
  //   const seriesItem = response.results[0]

  //   this.title = seriesItem.title
  //   this.total = seriesItem.volumes.total

  //   if (!options.ignoreYearsAggs) {
  //     this.localYearOptions = seriesItem.volumes.aggs.years
  //   }

  //   const items = seriesItem.volumes.results
  //     .filter(entry => entry.year !== null)
  //     .map(entry => {
  //       return {
  //         year: +entry.year,
  //         formatted: volIssTable(
  //           [entry],
  //           true, // is series
  //           seriesItem.volumes.total,
  //           this.id
  //         )[0],
  //       }
  //     })

  //   if (options.append) {
  //     this.items = [...this.items, ...items]
  //   } else {
  //     this.items = items
  //   }

  //   this.loading = false
  // }

  // showMore() {
  //   this.totalToShow += this.increaseBy

  //   if (this.idType === IdType.Series) {
  //     this.currentPage++
  //     return this.fetchVolumes({ append: true, ignoreYearsAggs: true })
  //   }

  //   return Promise.resolve()
  // }

  // resetShowMore() {
  //   this.totalToShow = this.increaseBy
  //   this.currentPage = 1
  // }

  // async applyFilter(options?: { scroll: boolean }) {
  //   // if (this.idType === IdType.Series) {
  //   //   await this.fetchVolumes({ append: false, ignoreYearsAggs: true })
  //   // }

  //   this.$router.push({
  //     query: {
  //       ...this.$route.query,
  //       startYear: this.startYear > 0 ? this.startYear.toString() : undefined,
  //       endYear: this.endYear > 0 ? this.endYear.toString() : undefined,
  //       last: undefined,
  //     },
  //   })

  //   this.$nextTick(() => {
  //     if (options && options.scroll) {
  //       scrollTo('#serials-voliss-results-total')
  //     }
  //   })
  // }

  // clearFilters(options?: { scroll: boolean }) {
  //   this.startYear = -1
  //   this.endYear = -1
  //   this.totalToShow = this.increaseBy
  //   this.currentPage = 1
  //   this.applyFilter(options)
  // }

  // isYearIncluded(itemYear: number) {
  //   if (this.appliedStartYear !== -1 && this.appliedEndYear === -1) {
  //     return itemYear >= this.appliedStartYear
  //   } else if (this.appliedStartYear !== -1 && this.appliedEndYear !== -1) {
  //     if (this.appliedStartYear > this.appliedEndYear) {
  //       return itemYear >= this.appliedEndYear && itemYear <= this.appliedStartYear
  //     } else {
  //       return itemYear >= this.appliedStartYear && itemYear <= this.appliedEndYear
  //     }
  //   }

  //   return false
  // }

  // async markLastSelectedItem() {
  //   const el = scrollTo(`#${this.lastSelectedItem}`)

  //   if (el === null) {
  //     if (this.canShowMore) {
  //       await this.showMore()
  //       this.$nextTick(async() => {
  //         await this.markLastSelectedItem()
  //       })
  //     } else {
  //       this.resetShowMore()
  //     }
  //   } else {
  //     el.classList.add('last-selected-item')
  //   }
  // }
}
