
import { SeriesAPI } from '@/js-api'
import { volIssTable } from '@/utils'
import { updateMathJax } from '@/utils/utils'
import { mixins } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import { Item } from './types'
import VolIssMixin from './volIssMixin'

@Component
export default class VolumeList extends mixins(VolIssMixin) {
  items: Item[] = []
  currentPage = 1
  increaseBy = 100

  //
  // LIFECYCLE HOOKS
  //
  updated() {
    updateMathJax()
  }

  //
  // WATCHERS
  //
  @Watch('$route', { immediate: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async onRouteChanged(newRoute: unknown, oldRoute: any) {
    this.lastSelectedItemId = this.$route.query.last as string
    this.startYear = this.$route.query.startYear ? +this.$route.query.startYear : -1
    this.endYear = this.$route.query.endYear ? +this.$route.query.endYear : -1

    const page = this.$route.query.page ? +this.$route.query.page : undefined
    const prevPage = oldRoute && oldRoute.query.page
      ? +oldRoute.query.page
      : page !== undefined ? page - 1 : undefined

    if (page === undefined) {
      this.currentPage = 1
    }

    if (page !== undefined && prevPage !== undefined && page > 1 && page > prevPage) {
      if (this.items.length === 0) {
        await this.fetchData({ append: true, fetchSize: page * this.increaseBy })
        this.currentPage = page
      } else {
        this.fetchData({ append: true })
      }
    } else if (page !== undefined && prevPage !== undefined && prevPage > page) {
      this.items.splice(page * this.increaseBy)
    } else {
      this.fetchData({ append: false })
    }
  }

  //
  // COMPUTED PROPERTIES
  //

  get showXMore() {
    return this.items.length + this.increaseBy > this.total
      ? this.total - this.items.length
      : this.increaseBy
  }

  get canShowMore() {
    return this.currentPage * this.increaseBy < this.total
  }

  get canShowLess() {
    return this.currentPage > 1
  }

  //
  // METHODS
  //

  async fetchData(options?:{append: boolean, fetchSize?: number}) {
    this.loading = true

    await this.fetchYears()
    await this.fetchVolumes(options)

    this.loading = false
  }

  async fetchYears() {
    const response = await SeriesAPI.volumesYears(this.id)

    this.yearOptions = response.results[0].volumes.aggs.years
  }

  async fetchVolumes(options?:{append: boolean, fetchSize?: number}) {
    const yearRange = this.getYearRange()

    const response = await SeriesAPI.volumes(
      this.id,
      this.currentPage,
      options && options.fetchSize ? options.fetchSize : this.increaseBy,
      yearRange.start,
      yearRange.end
    )
    const seriesItem = response.results[0]

    this.syncedTitle = seriesItem.title
    this.total = seriesItem.volumes.total

    const items = seriesItem.volumes.results
      .filter(entry => entry.year !== null)
      .map(entry => {
        return volIssTable(
          [entry],
          true, // is series
          seriesItem.volumes.total,
          this.id
        )[0]
      })

    if (options && options.append) {
      this.items = [...this.items, ...items]
    } else {
      this.items = items
    }
  }

  getYearRange() {
    const yearRange: { start?: number, end?: number } = {
      start: undefined,
      end: undefined,
    }

    if (this.startYear !== -1 && this.endYear === -1) {
      yearRange.start = this.startYear
    } else if (this.startYear !== -1 && this.endYear !== -1) {
      if (this.startYear > this.endYear) {
        yearRange.start = this.endYear
        yearRange.end = this.startYear
      } else {
        yearRange.start = this.startYear
        yearRange.end = this.endYear
      }
    }

    return yearRange
  }

  isYearIncluded(itemYear: number) {
    if (this.startYear !== -1 && this.endYear === -1) {
      return itemYear >= this.startYear
    } else if (this.startYear !== -1 && this.endYear !== -1) {
      if (this.startYear > this.endYear) {
        return itemYear >= this.endYear && itemYear <= this.startYear
      } else {
        return itemYear >= this.startYear && itemYear <= this.endYear
      }
    }

    return true
  }

  applyFilters() {
    this.currentPage = 1
    this.updateRoute()
  }

  clearFilters() {
    this.currentPage = -1
    this.updateRoute()
  }

  applyBottomFilters() {
    this.applyFilters()
    this.scrollToTopFilters()
  }

  clearBottomFilters() {
    this.clearFilters()
    this.scrollToTopFilters()
  }

  showMore() {
    this.currentPage++
    this.updateRoute()
  }

  showLess() {
    this.currentPage = 1
    this.updateRoute()
  }

  async updateRoute() {
    const query = {
      ...this.$route.query,
      startYear: this.startYear > 0 ? this.startYear.toString() : undefined,
      endYear: this.endYear > 0 ? this.endYear.toString() : undefined,
      page: this.currentPage > 0 ? this.currentPage.toString() : undefined,
      last: undefined,
    }

    // Don't update route if url params are the same
    if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
      return
    }

    this.$router.push({ query })
  }
}
