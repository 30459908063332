import SerialVolIss from '@/components/journal/SerialVolIss.vue'
import { scrollTo } from '@/utils/utils'
import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator'
import { IdType } from './types'
import VolIssFilters from './VolIssFilters.vue'

@Component({
  components: {
    SerialVolIss,
    VolIssFilters,
  },
})
export default class VolIssMixin extends Vue {
  @PropSync('title') syncedTitle!: string
  @Prop() idType!: IdType
  @Prop() id!: string

  @Ref('top-filters') topFilters!: Vue

  startYear = -1
  endYear = -1
  yearOptions: number[] = []
  loading = false
  total = 0
  lastSelectedItemId?: string

  //
  // LIFE CYCLE HOOKS
  //
  updated(): void {
    this.scrollToLastSelectedItem()
  }

  //
  // COMPUTED PROPERTIES
  //
  get internal(): void {
    return this.$store.state.AppConfigModule.appConfig.internal
  }

  //
  // METHODS
  //
  scrollToLastSelectedItem(): void {
    if (this.lastSelectedItemId) {
      const el = scrollTo(`#${this.lastSelectedItemId}`)
      if (el) {
        el.classList.add('last-selected-item')

        this.lastSelectedItemId = undefined
      }
    }
  }

  scrollToTopFilters(): void {
    this.$nextTick(() => {
      scrollTo(this.topFilters.$el as Element)
    })
  }
}
